import { useState, useEffect } from 'react';
import { isAndroid } from 'react-device-detect';

const useWindowSize = () => {
  let domWindow = typeof window !== 'undefined' && window;

  const [windowWidth, setWindowWidth] = useState(domWindow.innerWidth);
  const [windowHeight, setWindowHeight] = useState(domWindow.innerHeight);
  const [isLandscape, setIsLandscape] = useState(domWindow.innerWidth > domWindow.innerHeight || domWindow.innerWidth > 900);
  const [isPortrait, setIsPortrait] = useState(domWindow.innerWidth < domWindow.innerHeight);

  const modalActive = document?.getElementById('portal');

  const handleResize = () => {
    if (!modalActive) {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
      setIsLandscape(window.innerWidth > window.innerHeight || window.innerWidth > 900);
      setIsPortrait(window.innerWidth < window.innerHeight);
    }
  };

  useEffect(() => {
    let resizeId;

    const callback = () => {
      clearTimeout(resizeId);
      resizeId = !isAndroid && setTimeout(handleResize, 500);
    };

    window.addEventListener('resize', callback);

    return () => window.removeEventListener('resize', callback);
  }, [windowWidth, windowHeight]);

  return {
    windowWidth,
    windowHeight,
    isLandscape,
    isPortrait,
  };
};

export default useWindowSize;
